import React, { useEffect } from "react";
import "./header_home.css";
import jQuery from "jquery";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import navlogo from "../../Assets/About/Vector (36).svg";
import { BsDownload } from "react-icons/bs";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ImMenu } from "react-icons/im";
import axios from "../../network/api";
import { baseUrl } from "../../network/constants/Constants";

function HeaderHome(props) {
  const callBroucherFetch = (props) => {
    axios
      .get(baseUrl + "/webapp/download/gallery-broucher/")
      .then((response) => {
        let url = baseUrl + response.data.file_link;

        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
        console.log("brochersssss: " + baseUrl + response.data.file_link);
      })
      .catch((error) => {
        console.log(error);
        // setLoading(true);
      });
  };

  const downloadFileAtURL = () => {
    callBroucherFetch();
  };

  useEffect(() => {
    setAllLinksBlack();

    // register a listener on click of any item with the class nav-item
    // links.on("click", ".nav-item", function(){
    //   // turn all nav-items "active" class off
    //   for (var i = 0; i < links.length; i++) {
    //     links[i].classList.removeClass("active");
    //   }

    //   // turn on the "active" class for this item
    //   {$(this).addClass("active");}
    // });

    // Get current scroll position
    // let scrollY = window.pageYOffset;
    // Add an event listener listening for scroll
    window.addEventListener("scroll", navHighlighter);

    console.log("props" + props.pathname);

    const navi = document.getElementById("navbarhome-id");
    const topOfNav = navi.offsetTop;
    const element = document.getElementById("stickyClass");

    function fixNav() {
      if (window.scrollY > topOfNav) {
        element.classList.add("nav-sticky");
      } else {
        element.classList.remove("nav-sticky");
      }
    }
    window.addEventListener("scroll", fixNav);
  }, []);

  function setAllLinksBlack() {
    var nav = document.getElementById("nav");

    // Get all buttons with class="btn" inside the container
    var links = nav.getElementsByClassName("navLink");

    // Loop through the buttons and add the active class to the current/clicked button
    for (var i = 0; i < links.length; i++) {
      links[i].classList.add(props.color);
    }
  }

  function navHighlighter() {
    // Get all sections that have an ID defined
    const sections = document.querySelectorAll("section[id]");
    // Get current scroll position
    let scrollY = window.pageYOffset;

    // Now we loop through sections to get height, top and ID values for each
    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 50;
      let sectionId = current.getAttribute("id");

      /*
        - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
        - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
        */
      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        if (sectionId === "home") {
          document
            .querySelector(".navbar-nav a[href*=about]")
            .classList.remove("active");
        }
        // if (sectionId === "category") {
        //   document
        //     .querySelector(".navbar-nav a[href*=about]")
        //     .classList.remove("active");
        // }
        // if (sectionId === "recepies") {
        //   document
        //     .querySelector(".navbar-nav a[href*=products]")
        //     .classList.remove("active");
        // }
        // if (sectionId === "gallery") {
        //   document
        //     .querySelector(".navbar-nav a[href*=recepies]")
        //     .classList.remove("active");
        // }
        // if (sectionId === "career") {
        //   document
        //     .querySelector(".navbar-nav a[href*=gallery]")
        //     .classList.remove("active");
        // }
        // if (sectionId === "contactus") {
        //   document
        //     .querySelector(".navbar-nav a[href*=career]")
        //     .classList.remove("active");
        // }
        document
          .querySelector(".navbar-nav a[href*=" + sectionId + "]")
          .classList.add("active");
      } else {
        document
          .querySelector(".navbar-nav a[href*=" + sectionId + "]")
          .classList.remove("active");
      }
    });
  }

  return (
    <header id="navbarhome-id">
      <Navbar collapseOnSelect expand="lg" id="stickyClass" className="navbar">
        <Container>
          <Navbar.Brand className="nav-logo" href="/#home">
            <img src={navlogo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" varient="light">
            <ImMenu />
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand`}
                className="close"
              ></Offcanvas.Title>
              {/* <img src={navlogo} alt="logo" /> */}

              {/* <img src={close}></img> */}
            </Offcanvas.Header>
            <img src={navlogo} className="off-logo" alt="logo" />

            <Offcanvas.Body className="ms-lg-auto ">
              <Nav
                id="nav"
                className="nav flex-grow-1"
                activeKey={props.pathname}
              >
                <Nav.Link href="/#home" className="navLink pe-lg-3">
                  Home
                </Nav.Link>
                <Nav.Link href="/#about" className="navLink pe-lg-3">
                  About Us
                </Nav.Link>
                <Nav.Link href="/viewcategory" className="navLink pe-lg-3">
                  Category
                </Nav.Link>
                <Nav.Link href="/products" className="navLink pe-lg-3">
                  Products
                </Nav.Link>
                <Nav.Link href="/otherbrands" className="navLink pe-lg-3">
                  Other Brands
                </Nav.Link>
                <Nav.Link href="/recepies" className="navLink pe-lg-3">
                  Recipes
                </Nav.Link>
                <Nav.Link href="/gallery" className="navLink pe-lg-3">
                  Gallery
                </Nav.Link>
                <Nav.Link href="/career" className="navLink pe-lg-3">
                  Career
                </Nav.Link>
                <Nav.Link href="/contact" className="navLink pe-lg-3">
                  Contact Us
                </Nav.Link>

                <Button className="navBtn navLink" onClick={downloadFileAtURL}>
                  <span>Brochure </span>
                  <BsDownload size={12} className="ms-2" />
                </Button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  );
}

jQuery(function($) {
  // Code for changing active link on clicking
  var btns = $("navbar-nav > nav-link");
  if (btns.length !== 0) {
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function() {
        var current = document.getElementsByClassName("active");

        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
  }
});

export default HeaderHome;
