import React from "react";
import "./view-category.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../Footer/Footer";
import Header from "../../Header/header_home";
import ViewCategoryPage from "../View-Category/ViewCategoryPage/ViewCategoryPage.jsx";

function Viewcategory(fetchCategories) {
  return (
    <section id="category">
      <div>
        <div className="view-main">
          <div className="view-header">
            <Header color="black" />
          </div>
          <div>
            <Container className="view-maincontainer">
              <Row className="view-mainrow">
                <Col>
                  <div className="sub-heading">
                    <span>Fira Products</span>
                    <div className="text">The Flavour of India</div>
                  </div>
                </Col>
                <Col>
                  <div className="category-heading">
                    {/* Product Categories */}
                  </div>
                </Col>
              </Row>
            </Container>
            <ViewCategoryPage />
          </div>
        </div>
        <div>
          {/* <HomeCategoryPage fetchCategories={fetchCategories} /> */}
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </section>
  );
}

export default Viewcategory;
