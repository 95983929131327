import React, { useState } from "react";
import "./Item.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ImArrowRight2 } from "react-icons/im";
import Typography from "@mui/material/Typography";
import ellipse from "../../../Assets/Ellipse 9.png";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Header from "../../Header/header_home";
import Button from "@mui/material/Button";
import Carousel from "react-grid-carousel";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../network/constants/Constants";
import { useLocation } from "react-router-dom";

const Item = (props) => {
  const navigate = useNavigate();

  const { state = {} } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleImage = (index) => {
    setSelectedIndex(index);
  };

  const handleItemView = (obj, products) => {
    navigate(".", { state: { object: obj, products: products } });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="itemsection" id="item">
        <div className="item-main">
          <div className="item-head">
            <Header color="black" />
          </div>
          <div className="item-main-head">
            <div className="item-design">
              <Container>
                <div className="item-category"></div>

                <div className="item-name">{state.object.pd_name}</div>
              </Container>
            </div>
          </div>
          <Container className="item-container">
            <Row className="item-row">
              <Col lg={5}>
                
                <div className="top">
                  {state.object.images.length !== 0 &&
                    state.object.images[selectedIndex].image && (
                      <img
                        id="mainImage"
                        alt="main"
                        src={
                          baseUrl +
                          "/media/" +
                          state.object.images[selectedIndex].image
                        }
                      ></img>
                    )}
                  {state.object.images.length === 0 && (
                    <div className="no-image" style={{ height: "200px" }}>
                      No Images Available
                    </div>
                  )}
                </div>
                <div className="sub-item">
                  {state.object.images.map((image, index) => {
                    return (
                      <button
                        className="incredients-btn"
                        onClick={() => handleImage(index)}
                      >
                        <img
                          className="imageCarousel"
                          src={baseUrl + "/media/" + image.image}
                          alt="carousel"
                        ></img>
                      </button>
                    );
                  })}
                </div>
              </Col>

              <Col lg={6} sm={12} className="item-coltwo">
                <div className="itemhead">{state.object.category.title}</div>
                {/* <div className="item-mainhead">{state.object.pd_name}</div> */}
                <div>
                  <div className="item-subhead">Description</div>
                  <p>{state.object.pd_description}</p>
                </div>
                <div>
                  <div className="item-subhead">Ingredients</div>
                  <p>{state.object.pd_ingrediants}</p>
                </div>
                <div className="item-quantity">
                  <div>
                    {" "}
                    <span className="item-subhead">Quantity</span>
                    <p>{state.object.pd_quantity}</p>
                  </div>
                  <div>
                    {" "}
                    <span className="item-subhead">Type</span>
                    <p>{state.object.pd_type}</p>
                    <span className="elipse">
                      <img src={ellipse} alt="round" />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="item-lastrowcontainer">
          <p className="p1">
            Similar Items
            <span className="spitem">
              &nbsp;({state.products.length}&nbsp;Items)
            </span>
          </p>

          <Row className="item-rowlast">
            <div className="item-scroll">
              <Carousel
                touch={true}
                slide={true}
                cols={4}
                rows={1}
                gap={10}
                hideArrow={false}
              >
                {state.products &&
                  state.products.length > 0 &&
                  state.products.map((obj, index) => {
                    {
                      console.log(baseUrl + JSON.stringify(obj.images));
                    }

                    return (
                      <Carousel.Item>
                        <Col>
                          <Card
                            key={index}
                            className="item-card"
                            onClick={() => handleItemView(obj, state.products)}
                          >
                            {obj.images.length !== 0 ? (
                              <img
                                className="item-image"
                                src={baseUrl + "/media/" + obj.images[0].image}
                                alt="item"
                              ></img>
                            ) : (
                              <div className="text-center">
                                No Image Available
                              </div>
                            )}
                            <CardContent>
                              <Typography
                                gutterBottom
                                className="item-title"
                                component="div"
                              >
                                {obj.pd_name}
                              </Typography>
                            </CardContent>
                            <CardActions className="item-cardaction">
                              {/* <div className="gmview"> */}
                              <Typography className="item-gram">
                                {obj.pd_quantity}
                              </Typography>
                              <span className="g-view">View</span>
                              {/* </div> */}
                              <Button
                                className="item-btn"
                                onClick={() =>
                                  handleItemView(obj, state.products)
                                }
                              >
                                <ImArrowRight2 className="item-arrow" />
                              </Button>
                            </CardActions>
                          </Card>
                        </Col>
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
            </div>
          </Row>
        </Container>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Item;
