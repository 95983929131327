import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import typeicon from "../../../Assets/Recepies/typeicon.svg";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import { ImArrowRight2 } from "react-icons/im";

function select(props) {
  let { img, type, title, method, time, subtype, ingredient, desc } =
    props.common || {};

  return (
    <div>
      <Card
      
        className="recepie-card"
      >
        <CardMedia
          className="recepie-image"
          image={img}
        ></CardMedia>
        <CardContent className="recepie-content">
          <Typography gutterBottom className="recepie-type" component="div">
            {type}
          </Typography>
          <Typography gutterBottom className="recepie-title" component="div">
            {title}
          </Typography>
          <Typography gutterBottom className="recepie-method" component="div">
            <img src={method} alt="method"/>
            &nbsp;
            {method}
          </Typography>
          <Typography gutterBottom className="recepie-time" component="div">
            <img src={time} alt="time"/>
            &nbsp;
            {time}
          </Typography>{" "}
          <Typography gutterBottom className="recepie-subtype" component="div">
            <img src={typeicon} alt="icon"/>
            &nbsp;
            {subtype}
          </Typography>
          <Typography
            gutterBottom
            className="recepie-ingredient"
            component="div"
          >
            <img src={ingredient} alt="incredient"/>
            &nbsp;
            {ingredient}
          </Typography>
          <Typography gutterBottom className="recepie-desc" component="div">
            {desc}
          </Typography>
        </CardContent>
        <CardActions className="selected-cardaction">
          <Button className="recepie-btn">
            <ImArrowRight2 className="recepie-arrow" />
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

export default select;
