import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { ImArrowRight2 } from "react-icons/im";
import "./ViewCategoryPage.css";
import { useNavigate } from "react-router-dom";
import axios from "../../../../network/api.js";
import { baseUrl } from "../../../../network/constants/Constants.js";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function ViewCategoryPage() {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/category/list/")
      .then((response) => {
        setLoading(false);
        console.log("data from api" + response.data);
        setCategory(response.data);
      })
      .catch((error) => {
        console.log(error + "category errorrrrrrrrrrrrr");
        setLoading(true);
      });
  };

  const navigate = useNavigate();

  function products() {
    navigate("/products");
    window.scrollTo(0, 0);
  }

  function product(id) {
    navigate("/products", { state: { id: id } });
    window.scrollTo(0, 0);
  }

  return (
    <div className="category-bg2">
      <div className="view-category-design">
        <div className="homecategory-heading">
          <p>Our Category</p>
        </div>
        <Container className="HCP">
          <Row className="homecategory-row">
            {console.log("Category" + category)}

            {category &&
              category
                .sort((a, b) => (a.id > b.id ? 1 : -1))
                .map((categories, index) => {
                  return (
                    <Col lg={4} className="home-category-col">
                      <img
                        src={categories.image}
                        alt="foods"
                        onClick={() => {
                          product(categories.id);
                        }}
                      />
                      <div className="product-name pt-3">
                        {categories.title}
                      </div>
                      <div className="product-desc pt-2">
                        {categories.description}
                      </div>
                      <div className="view-details pt-3">
                        <Button
                          variant="text"
                          className="more-btn"
                          onClick={() => {
                            product(categories.id);
                          }}
                        >
                          More Details&nbsp;
                          <ImArrowRight2 className="more-icon" />
                        </Button>
                      </div>
                    </Col>
                  );
                  {
                    /* } */
                  }
                })}
          </Row>
          {loading && (
            <Box sx={{ display: "center", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          <div className="view-more-ctpage">
            <Button className="all-btn" onClick={products}>
              View All
            </Button>
          </div>{" "}
        </Container>
      </div>
    </div>
  );
}

export default ViewCategoryPage;
