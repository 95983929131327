import React, { useState, useEffect } from "react";
import "./OtherBrands.css";
import Header from "../../Header/header_home";
import Footer from "../../Footer/Footer";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { BsDownload } from "react-icons/bs";
import BrandComponent from "./BrandComponent";
import Box from "@mui/material/Box";

import axios from "../../../network/api";
import { baseUrl } from "../../../network/constants/Constants";
import CircularProgress from "@mui/material/CircularProgress";

function OtherBrands() {
    const [loading, setLoading] = useState(true);
    const [brandDataArray, setbrandDataArray] = useState("");


    useEffect(() => {
        fetchBrands();
    }, []);


    const fetchBrands = (props) => {
        setLoading(true);

        axios
            .get(baseUrl + "/webapp/partners/")
            .then((response) => {
                setLoading(false);
                setbrandDataArray(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
                setLoading(true);
            });
    };

    return (
        <section className="otherbrands" id="otherbrands">
            <div className="contact-head">
                <Header color="black" />
            </div>
            <div className="item-main-head">
                <div className="item-design">
                    <Container>
                        <div className="item-category"></div>
                        <div className="item-head">We Are</div>
                        <div className="ob-item-title">Exclusive GCC Distributor</div>
                    </Container>
                </div>
            </div>

            {loading && (
                <Box className="nodata-layout" sx={{ display: "center", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </Box>
            )}

            {!loading &&
                brandDataArray &&
                brandDataArray.map((v, index) => {
                    return (
                        <>
                            <BrandComponent brandObject={brandDataArray[index]}></BrandComponent>
                        </>
                    );
                })}






            <div className="contactus-footerdiv">
                <Footer />
            </div>
        </section>
    );
}

export default OtherBrands;
