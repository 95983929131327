import React from "react";
import Button from "react-bootstrap/Button";
import { BsDownload } from "react-icons/bs";
import "./BrandComponent.css";
import Container from "react-bootstrap/Container";

const BrandComponent = (props) => {

    const downloadFileAtURL = (pdf) => {
        let url = pdf;
    
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      };

    return (<div className="brand-section" style={{backgroundColor: props.brandObject.background_color}}>
    <Container>
        <div className="brand-content">
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <div className="brand-cluster">
                        <img src={props.brandObject.image} className="img-fluid"/>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="brand-details">
                        <img src={props.brandObject.logo} alt="Brand Logo" />
                        <div className="brand-title" style={{color: props.brandObject.text_color}}>
                            {props.brandObject.title}
                        </div><div className="brand-desc" style={{color: props.brandObject.text_color}}>
                            {props.brandObject.description}
                        </div>
                        <Button className="navBtn navLink" onClick= {()=>{downloadFileAtURL(props.brandObject.pdf)}}>
                            <span>Brochure </span>
                            <BsDownload size={12} className="ms-2" />
                        </Button>

                    </div>
                </div>
            </div>
        </div>
    </Container>
</div>);
}

export default BrandComponent;