import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cook from "../../../../Assets/container4/cook.png";
import fd1 from "../../../../Assets/container4/Round circle food 5.png";
import fd2 from "../../../../Assets/container4/Round circle food.png";
import Righttop from "../../../../Assets/container4/Round circle food 3.png";
import lefttop from "../../../../Assets/container4/Round circle food 4.png";
import rightbottom from "../../../../Assets/container4/Round circle food 2.svg";
import { Container } from "react-bootstrap";
import "./containerFour.css";

function ContainerFour() {
  return (
    <div className="color-bg">
      <div className="four-bg">
        <div className="cooking">
          <Container>
            <div className="left-top1">
              <img src={lefttop} alt="top" />
            </div>
            <div className="round-top">
              <img src={Righttop} alt="top" />
            </div>
          </Container>

          <Row>
            <Col lg={2}>
              <div className="fd1">
                <img src={fd1} alt="food1" />
              </div>
              <div className="fd2">
                <img src={fd2} alt="food2" />
              </div>
            </Col>
            <Col lg={7}>
              <div className="indian-content">
                <div className="four-heading">
                  <span className="span1">Indian Ideas</span>
                  <br></br>
                  <span className="span2">Global Flavours</span>
                </div>
                <div className="four-content">
                  <p>
                    India is a melting pot of cultures & culinary arts. Having
                    welcomed people of different cultures and lived with them
                    for centuries, Indians have developed a taste for
                    exploration and innovation in their kitchens. Fira Foods is
                    inspired by this explorative and innovative spirit. A spirit
                    that’s increasingly being shared by food makers and food
                    consumers across the world. With a shelf full of differently
                    delicious food products, brand Fira invites the world to
                    try, experiment and enjoy the flavors of the future.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={2} className="cooking-col">
              <img src={cook} alt="cooking" />
            </Col>
            <div className="right-bottom1">
              <img src={rightbottom} alt="top" />
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ContainerFour;
