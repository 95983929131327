import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../Header/header_home";
import Footer from "../../Footer/Footer";
import brochure from "../../../Assets/Gallery/brochure.png";
import image from "../../../Assets/Gallery/image.png";
import video from "../../../Assets/Gallery/video.png";
import leaf from "../../../Assets/Gallery/basil-leaves-6256 2.svg";
import "./Gallery.css";
import icon from "../../../Assets/Gallery/icon.png";
import "react-html5video/dist/styles.css";
import ReactPlayer from "react-player/youtube";
import axios from "../../../network/api";
import { baseUrl } from "../../../network/constants/Constants";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

const Gallery = (props) => {
  const [loading, setLoading] = useState("");
  const [videos, setVideos] = useState("");
  const [images, setImages] = useState("");
  const [brochures, setBrochures] = useState(true);
  const [videosection, setVideoSection] = useState("");
  const [imagesection, setImageSection] = useState("");
  const [brochuresection, setBrochureSection] = useState("");
  const [currentSlide, setCurrentSlide] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setCurrentSlide(0); // Reset the current slide when the modal opens
  }, [handleShow]);

  useEffect(() => {
    setCurrentSlide(0); // Reset the current slide when the modal opens
  }, []);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
    handleShow(true);
  };

  const ImageFullScreen = (index) => {
    setSelectedIndex(index);
    handleShow(true);
    console.log("SelectedIndex" + index);
  };

  const downloadFileAtURL = (pdf) => {
    let url = pdf;

    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  function handleVideos() {
    setVideos(true);
    setImages(false);
    // setHeading(false);
    setBrochures(false);
  }
  function handleImages() {
    setVideos(false);
    setImages(true);
    // setHeading(false);
    setBrochures(false);
  }

  function handleBrochures() {
    setVideos(false);
    setImages(false);
    // setHeading(false);
    setBrochures(true);
  }

  useEffect(() => {
    fetchVideos();
    fetchImage();
    fetchBrochure();
  }, []);

  const fetchVideos = (props) => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/gallery-video/list/")
      .then((response) => {
        setLoading(false);

        setVideoSection(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  const fetchImage = (props) => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/gallery-image/")
      .then((response) => {
        setLoading(false);

        setImageSection(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  const fetchBrochure = (props) => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/gallery-broucher/")
      .then((response) => {
        setLoading(false);

        setBrochureSection(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  return (
    <section id="gallery">
      <div className="gallery-section" id="gallery">
        <div className="headergallery">
          <Header color="black" />
        </div>
        <Container className="gallery-container">
          <Row className="gallery-containerrowone">
            <Col>
              <span>Resources & Media</span>
              {/* <p>Lore Ipsum text here</p> */}
            </Col>

            <Col>
              <img className="gallery-img" src={leaf} alt="leaf"></img>
            </Col>
          </Row>
          <Row className="gallery-containerrowtwo" lg={2}>
            <div className="gallery-click">
              {brochures ? (
                <div
                  onClick={handleBrochures}
                  className="gallerybtn-div selected"
                >
                  <img src={brochure} alt="brochure" />
                  <p>Brochure</p>
                </div>
              ) : (
                <div onClick={handleBrochures} className="gallerybtn-div">
                  <img src={brochure} alt="brochure" />
                  <p>Brochure</p>
                </div>
              )}

              {images ? (
                <div onClick={handleImages} className="gallerybtn-div selected">
                  <img src={image} alt="pic" />
                  <p>photos</p>
                </div>
              ) : (
                <div onClick={handleImages} className="gallerybtn-div">
                  <img src={image} alt="gallery" />
                  <p>photos</p>
                </div>
              )}
              {videos ? (
                <div onClick={handleVideos} className="gallerybtn-div selected">
                  <img src={video} alt="gallery" />
                  <p>Videos</p>
                </div>
              ) : (
                <div onClick={handleVideos} className="gallerybtn-div">
                  <img src={video} alt="gallery" />
                  <p>Videos</p>
                </div>
              )}
            </div>
          </Row>
        </Container>
        <Container className="selected-section">
          <Row className="selected-sectionfirstrow">
            <div onClick={handleBrochures}>
              {brochures && <p>Brochures</p>}

              {videos && <p>Videos</p>}

              {images && <p>Images</p>}
            </div>
          </Row>

          <Row className="abcd">
            {videos &&
              videosection &&
              videosection.map((v, index) => {
                return (
                  <>
                    <Col lg={4}>
                      <div className="video-grid">
                        <div className="manin-videosection">
                          <div className="video">
                            <ReactPlayer
                              url={v.youtube_link}
                              className="reactplayer"
                            />
                          </div>
                          <p className="video-title">{v.title}</p>
                        </div>

                        <div className="video-content">
                          <p className="video-desc">{v.description}</p>
                          <p className="video-date">{v.date}</p>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })}

            {loading && (
              <Box sx={{ display: "center", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            <Row className="gallery-img-row">
              {images &&
                imagesection &&
                imagesection
                  .sort((a, b) => (a.id > b.id ? -1 : 1))
                  .map((i, index) => {
                    return (
                      <Col lg={4} className="img-row ">
                        <div
                          className="crd"
                          onClick={() => ImageFullScreen(index)}
                        >
                          <div key={index} className="image-grid">
                            <img src={i.image} alt="brochure" />
                          </div>
                          <div className="img-title">{i.title}</div>
                        </div>
                      </Col>
                    );
                  })}

              {imagesection && (
                <Modal
                  show={modalShow}
                  {...props}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header
                    closeButton
                    onClick={handleClose}
                  ></Modal.Header>
                  <Modal.Body>
                    <Col className="imagemodal" container>
                      <Col>
                        <Carousel
                          indicators={false}
                          activeIndex={selectedIndex}
                          onSelect={(index) => setSelectedIndex(index)}
                        >
                          {imagesection.map((galleryImg, index) => (
                            <Carousel.Item key={index}>
                              <div className="md-image">
                                <img
                                  className=""
                                  src={galleryImg.image}
                                  alt={`Slide ${index}`}
                                />
                              </div>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                        <div className="modal-img-title">
                          {imagesection[selectedIndex].title}
                        </div>
                      </Col>
                    </Col>
                  </Modal.Body>
                </Modal>
              )}
            </Row>
            {brochures &&
              brochuresection &&
              brochuresection.map((obj, index) => {
                return (
                  <Col lg={6} className="br">
                    <div
                      className="brochure-div"
                      key={index}
                      onClick={() => {
                        downloadFileAtURL(obj.pdf);
                      }}
                    >
                      <img
                        src={obj.broucher}
                        className="brochure-mainpic"
                        alt="brochure"
                      />
                      <div className="brochure-title">{obj.link}</div>
                      <div className="brochure-btn">
                        <button
                          onClick={() => {
                            downloadFileAtURL(obj.pdf);
                          }}
                        >
                          <img src={icon} alt="icon" />
                        </button>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>

        <div>
          <Footer />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
